import axios from 'axios';
import jwt_decode from 'jwt-decode';
import qs from 'qs';
import * as Sentry from '@sentry/react';
import { CognitoUser, CognitoUserPool, AuthenticationDetails } from 'amazon-cognito-identity-js';

import appSettings from '../../app-settings';
import { enableMocking } from '../mock-axios';
import { AUTH_SESSION_KEY } from '../constants';

const { authUrl } = appSettings;

const LOCATION_PATHNAME_KEY = 'LOCATION_PATHNAME';

export const hasValidSession = () => {
  const authToken = sessionStorage.getItem(AUTH_SESSION_KEY);
  if (authToken) {
    const { exp, 'custom:tenant_id': tenantId } = jwt_decode(authToken);

    if (tenantId === 'demo') {
      enableMocking();
    }

    if (exp * 1000 > Date.now()) {
      return true;
    } else {
      sessionStorage.clear();
    }
  }
  return false;
};

export const authenticate = async (redirectUri, appClient, navigate, preservePathname = true) => {
  try {
    const queryString = window.location.search;

    const locationPathname = window.location.pathname;

    if (locationPathname !== '/' && preservePathname) {
      // Preserve original pathname
      sessionStorage.setItem(LOCATION_PATHNAME_KEY, locationPathname);
    }

    if (!queryString || !queryString.toLowerCase().includes('code')) {
      // if code not in url, route to log in page
      window.location.replace(
        `${authUrl}/login?client_id=${appClient}&response_type=code&scope=email+openid+phone+profile+aws.cognito.signin.user.admin&redirect_uri=${redirectUri}`,
      );

      return false;
    }

    const code = queryString.split('?code=')[1];

    const data = qs.stringify({
      grant_type: 'authorization_code',
      code,
      client_id: appClient,
      redirect_uri: redirectUri,
    });

    const originalPathname = sessionStorage.getItem(LOCATION_PATHNAME_KEY) ?? '/';

    // Redirect to original pathname
    navigate(originalPathname, { replace: true });

    const result = await axios.post(`${authUrl}/oauth2/token`, data, {
      headers: {
        'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (result.data.id_token) {
      sessionStorage.setItem(AUTH_SESSION_KEY, result.data.access_token);

      const { 'custom:tenant_id': tenantId } = jwt_decode(result.data.id_token);

      if (tenantId === 'demo') {
        enableMocking();
      } else {
        Sentry.setUser({ id: tenantId });
      }
    }

    return true;
  } catch (error) {
    Sentry.captureException(error);
    return false;
  }
};

export const sigInWithEmailAndPassword = async (email, password) => {
  return new Promise((resolve, reject) => {
    const userPool = new CognitoUserPool({
      UserPoolId: appSettings.cognitoUserPoolId,
      ClientId: appSettings.cognitoUserClientId,
    });

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        const idToken = result.getIdToken().getJwtToken();
        const accessToken = result.getAccessToken().getJwtToken();
        sessionStorage.setItem(AUTH_SESSION_KEY, accessToken);

        const { 'custom:tenant_id': tenantId } = jwt_decode(idToken);
        Sentry.setUser({ id: tenantId });

        resolve();
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};

export const getUserRole = () => {
  const authToken = sessionStorage.getItem(AUTH_SESSION_KEY);
  if (authToken) {
    const { 'cognito:groups': groups } = jwt_decode(authToken);
    const role = groups?.find((group) => group.endsWith('-tenant'));
    return role;
  }
  return '';
};

export const signOut = () => {
  sessionStorage.clear();
  Sentry.setUser(null);
  window.location.replace('https://donorspring.com');
};
